<!-- 学生列表 -->
<!-- 学校花名册 -->
<template>
     <div class="userList">
        <!-- top -->
        <el-breadcrumb separator="/">
            <el-breadcrumb-item >文件管理</el-breadcrumb-item>
            <el-breadcrumb-item>报告处理列表</el-breadcrumb-item>
        </el-breadcrumb>
       
        <div class="content">
            <!-- tableTop -->
            <div class="tableTop">
                <div class="tableTop_left">
                    <div v-if="!isSelect" class="new-down-active" @click="download">下载原始文件</div>
                    <div v-if="isSelect" class="new-down-noactive">下载原始文件</div>

                    <div v-if="!isReady" class="new-ready-active" @click="readyStart">立即执行</div>
                     <div v-if="isReady" class="new-ready-noactive">立即执行</div>
                </div>
                <div class="tableTop_right">
                    <div class="search">
                    <el-input
                            placeholder="请输入内容"
                            prefix-icon="el-icon-search"
                            @change="search"
                            v-model="seachcon"
                            class="elSerch"
                            >
                            <template slot="append" style="background-color: #00CCCC;cursor pointer">搜索</template>
                    </el-input>
                </div>
                <div v-if="!isSelect" class="delete" @click="deleteReports">删除</div>
                 <div v-if="isSelect" class="delete-no" @click="deleteReports">删除</div>
                </div>
            </div>
            <ReportProcessListTable ref="pfl"/>
        </div>
     </div>
</template>
<script>
import ReportProcessListTable from '@/components/table/reportProcessListTable.vue'
import {deleteReport,downloadFile,processFile} from '@/api/upload.js'
export default {
    name:'userList',
    components:{
        ReportProcessListTable,
    },
    data(){
        return{

            // 表头搜索框的任意搜索
             seachcon:null,
             isSelect:false,
             isReady:false,
             tableSelect:null,//子表勾选状态
             readyId:null,
            
        }
    },
    methods:{
        // 开始查询
        search(){
            //console.log("查询");
            this.$refs.pfl.getData(1,10,this.seachcon);
            
        },
        //下载原始数据
        download(){
            const loading = this.$loading({
            lock: true,
            text: '下载中',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
            });
            var tempd = [];
            this.tableSelect.forEach((item,index)=>{
                var id = item.id;
                tempd.push(id);
            });

            downloadFile({
                id:tempd[0]
            }).then(res => {
                    if (!res || !res.data) {
                        this.$message.error('暂无');
                      }
                    if (res.data.type === 'application/json') {
                        //非进制流文件 在这里
                        const reader = new FileReader()
                        reader.readAsText(res.data)
                        reader.onload = e => {
                        const resData = JSON.parse(reader.result)
                        if(resData.status!=200){
                            loading.close();
                            this.$message.error(resData.message)
                        }
                    }
                    }
                    else{
                        const { data, headers } = res
                        const newHeader = decodeURI(headers['content-disposition']);//进行解码  decodeURI()方式解码
                        //console.log("头文件",newHeader.split(';')[1].split('=')[1]);
                        const fileName = newHeader.split(';')[1].split('=')[1];//提取文件名称
                        const blob = new Blob([data], { type:'application/zip'})
                        let dom = document.createElement('a')
                        let url = window.URL.createObjectURL(blob)
                        dom.href = url
                        dom.download = decodeURI(fileName)
                        dom.style.display = 'none'
                        document.body.appendChild(dom)
                        dom.click();
                        dom.parentNode.removeChild(dom)
                        window.URL.revokeObjectURL(url)
                        loading.close();
                        this.$refs.pfl.getData(1,10,this.seachcon);
                    }    
            }).catch((err) => {
                console.log("err",err)
                    
            });         

        },
        //立即执行
        readyStart(){
            var tempr = [];
            this.tableSelect.forEach((item,index)=>{
                if((item.status=="准备") || (item.status=="错误")){
                    var id = item.id;
                    tempr.push(id);
                }
            });
            processFile({
                id:tempr[0]
            }).then(res=>{
                if(res.data.status == 200){
                     this.$message({
                     message: '执行成功！',
                     type: 'success'
                  });
                 this.$refs.pfl.getData(1,10,this.seachcon);
                }else{
                    this.$message.error(res.data.message);
                }
            })
        },
        //删除上传报告
    
        deleteReports(){
            var temp= [];
            this.tableSelect.forEach((item,index)=>{
                var id = item.id;
                temp.push(id);
            })
            deleteReport({
                ids:temp
            }).then(res=>{
                if(res.data.status==200){
                    this.$message({
                     message: '删除成功！',
                     type: 'success'
                  });
                    this.$refs.pfl.getData(1,10,this.seachcon);
                }else{
                    this.$message.error(res.data.message);
                }
            }).catch(res=>{
                this.$message.error(res);
            })
        }
    }
}
</script>
<style scoped lang="stylus">

//面包屑样式
/deep/.el-breadcrumb__item:last-child .el-breadcrumb__inner{
    font-weight: bold !important;
  }

/deep/.el-input__icon {
    line-height: 0 !important;
}

/deep/.el-icon-date:before{
    line-height: 0.2rem !important;
    font-size:0.11rem
}

/deep/.el-input__inner{
    height: .30rem !important;
    font-size: .11rem !important
  }
/deep/.el-input-group__append{
    border-block-color:#00CCCC !important;
    color: #fff !important;
    background-color: #00CCCC !important;
    border: 0 !important;
}
/deep/.el-button{
    padding: 0px 0 0 4px !important;
    text-align: center !important;
    color: #fff !important;
    font-size: 0.11rem !important;
  }
/deep/.el-date-editor .el-range-input{
    font-size: .11rem !important
}


// component style 
    //联合搜索框样式
.elSerch>>>.el-input__inner
    border: 1px solid #00CCCC !important
    width: 200px
    border-radius: 4px 0 0 4px

    
// 正常嵌套样式写法
.new-down-noactive
    width: 116px 
    height 32px 
    margin-right: 24px
    background-color:#f4f4f4
    cursor disable
    color: #aeaeae
    line-height: 32px 
    text-align: center
    font-size: 14px
.new-down-active
    width: 116px 
    height 32px 
    margin-right: 24px
    background-color:#00CCCC
    cursor pointer
    color: #fff
    line-height: 32px 
    text-align: center
    font-size: 14px

.new-ready-noactive
    width: 116px 
    height 32px 
    margin-right: 24px
    background-color:#F4F4F4
    cursor disable
    color: #aeaeae
    line-height: 32px 
    text-align: center
    font-size: 14px
.new-ready-active
    width: 116px 
    height 32px 
    margin-right: 24px
    background-color:#00CCCC
    cursor pointer
    color: #fff
    line-height: 32px 
    text-align: center
    font-size: 14px


.content
    overflow hidden
    position: relative
    margin-top: 16px
    padding: 20px
    background-color: #FFFFFF
  
    .tableTop 
        display: flex
        justify-content: space-between
        align-items: center
        margin-top: 20px
        .tableTop_left
            display: flex
        .tableTop_right
            display: flex
            align-items: center
            .delete
                width: 64px
                height: .25rem
                background-color: #FC5555
                text-align: center
                line-height:.25rem
                color: #fff
                font-size: 14px
                margin-left: 16px
                cursor pointer
                border-radius: 4px
            .delete-no
                width: 64px
                height: .25rem
                background-color: #f4f4f4
                text-align: center
                line-height:.25rem
                color: #aeaeae
                font-size: 14px
                margin-left: 16px
                cursor disable
                border-radius: 4px

</style>