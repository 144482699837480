<!-- 花名册列表 -->
<template>
<div class="table">
  <el-table
    ref="multipleTable"
    :data="tableData"
    :highlight-current-row="true"
    tooltip-effect="dark"
    style="width:100%;color:#858585;"
    @selection-change="selectRow"
    :header-cell-style="tableHeaderCellStyle">
    <el-table-column
     type="selection"
     fill="#00CCCC"
     align="center"
     fixed="left">
    </el-table-column>
    <!-- 表头开始 -->
    <el-table-column
      prop="originalFileName"
      label="文件名"
      align="center"
      show-overflow-tooltip>
    </el-table-column>
    <el-table-column
      prop="status"
      label="状态"
      align="center">
    </el-table-column>
    <el-table-column
      prop="createBy"
      label="创建者"
    show-overflow-tooltip
      align="center">
    </el-table-column>
    <el-table-column
      prop="remarks"
      label="备注"
     show-overflow-tooltip
      align="center">
    </el-table-column>
    <el-table-column
      prop="startTime"
      label="开始时间"
      align="center">
    </el-table-column>
    <el-table-column
      prop="endTime"
      label="结束时间"
      align="center">
    </el-table-column>
     <el-table-column
      prop="createTime"
      label="创建时间"
      align="center">
    </el-table-column>
    
  </el-table>
  <!-- 分页 -->
  <div class="page">
  <!-- left -->
  <span class="pageLeft">共 {{count}} 条记录 第 {{currentPage}} / {{tatalPage}}页</span>
  <!-- right -->
  <el-pagination
      @size-change="SizeChange"
      background
      @current-change="CurrentChange"
      @prev-click="prevClick"
      @next-click="nextClick"
      :current-page="currentPage"
      :page-sizes="[10, 20, 30, 40]"
      :page-size="onlyPage"
      :pager-count="5"
      layout="sizes, prev, pager, next, jumper"
      :total="count">
    </el-pagination>
  </div>
</div>
</template>

<script>
import {processReportList} from '@/api/upload.js'
  export default {
    beforeRouteLeave(to,from,next){
        from.meta.keepAlive = false;
        next();
    },
    data() {
      return {
        tableData: [],//表格数据
        multipleSelection: [],// tableCheckboxValue
        // 分页相关
        currentPage:1,// 当前页面,默认1
        tatalPage:0,//总页数,为加载时先为0，页面首次加载后去请求即可
        count:0,//总的条数记录
        onlyPage:10,// 当前页面可以展示多少条数据,默认10 

      }
    },
      created(){
           this.getData(1,10,null);
    },

    //监听勾选值
    watch:{
        multipleSelection:{
            handler(pre){
              var tem = pre;
              if(tem.length==0){
                
                this.$parent.isSelect = true;
                this.$parent.isReady = true;
              }
              if(tem.length>0){
                this.$parent.isSelect = false;
                //console.log(tem);
                this.$parent.tableSelect = tem ;
                var temp = [];
                tem.forEach((a,b)=>{
                  if((a.status=="准备")||(a.status=="错误")){
                    this.$parent.isReady = false;
                  }
                })
                /*
                if(tem[0].status == "准备"){
                  this.$parent.isReady = false;
                  this.$parent.readyId = tem[0];
                }else{
                  this.$parent.isReady = true;
                }
                */
               }
               /*
             if(tem.length>1){
                
                this.$parent.isSelect = true;
                this.$parent.isReady = true;
                 this.$message({
                  message: '当前仅支持勾选单个！',
                  type: 'warning'
                });
              }
              */
            },
            deep:true,
            immediate:true
        }
    },

    methods: {
      //表头样式回调
      tableHeaderCellStyle(){
          return "color:#fff;background-color:#00CCCC";
        },
      // getData
      getData(currentPage,onlyPage,seachALL){
              const rLoading = this.openLoading();// 开始loading
              processReportList({
              originalFileName:seachALL,
              currentPage: currentPage,
              pageSize: onlyPage
            }).then((res)=>{
              if(res.data.status == 200){
                 this.tableData = res.data.data.records;
                 this.tatalPage =parseInt(res.data.data.size);
                 this.count =parseInt(res.data.data.total);
                 rLoading.close() ;// 关闭loading
              }else{
                this.$message.error(res.data.message);
              }
            }).catch(res=>{
               this.$message.error(res);
            })
      },
      // 复选框选项
      selectRow(val) {
       this.multipleSelection = val;
      },

      // 分页相关
      SizeChange(val){
        // pageSize 改变时会触发  回调参数 每页条数
       // console.log("每页多少条",val);
        this.onlyPage = val;
        this.getData(this.currentPage,this.onlyPage,this.$parent.seachcon);//更新数据
      },
      CurrentChange(val){
        // currentPage 改变时会触发  回调参数 当前页
       // console.log("当前页面",val);
        this.currentPage = val;
        this.getData(this.currentPage,this.onlyPage,this.$parent.seachcon);//更新数据
      },
      prevClick(val){
        // 上一页
        // console.log("上一页的页数是",val);
        this.currentPage = val ;
        this.getData(this.currentPage,this.onlyPage,this.$parent.seachcon);//更新数据

      },
      nextClick(val){
        // 下一页
        // console.log("下一页的页数是",val);
        this.currentPage = val ;
        this.getData(this.currentPage,this.onlyPage,this.$parent.seachcon);//更新数据

      },

    },
   
  }
</script>
<style scoped>
.table{
    margin-top: .1563rem;
   
}
.el-button{
    color: #00A3A3 !important;
}
.action{
    display: flex;
    flex-direction: column;
}
.actionP{
  cursor: pointer;
  color: #00CCCC;
}
.page{
  display: flex;
  margin-top: .3125rem;
  justify-content: space-between;
  align-items: center;
}
.pageLeft{
  color: #858585;
  font-size: .1094rem;
  line-height: .1875rem;
}
.el-pagination{
  color: #858585 !important;
  font-size: .1094rem !important;
  line-height: .1875rem !important;
}
</style>